import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import TrkdOldNewsGrid from "~/components/TrkdOldNewsGrid.vue";
import TrkdSigDevGrid from "~/components/TrkdSigDevGrid.vue";
import useAnalytics from "~/functions/useAnalytics";
export default defineComponent({
  components: {
    TrkdOldNewsGrid: TrkdOldNewsGrid,
    TrkdSigDevGrid: TrkdSigDevGrid
  },
  setup: function setup() {
    var _useAnalytics = useAnalytics(),
      events = _useAnalytics.events,
      sendEvent = _useAnalytics.sendEvent;
    return {
      events: events,
      sendEvent: sendEvent
    };
  },
  computed: {
    data: function data() {
      // do I need to protect this better?
      return this.$store.state.trkd.companyHeadlines;
    },
    companyHeadlines: function companyHeadlines() {
      var _this$$store$state$tr;
      return ((_this$$store$state$tr = this.$store.state.trkd.companyHeadlines) === null || _this$$store$state$tr === void 0 || (_this$$store$state$tr = _this$$store$state$tr.order) === null || _this$$store$state$tr === void 0 ? void 0 : _this$$store$state$tr.length) > 0;
    },
    companySigDevs: function companySigDevs() {
      return this.$store.state.trkd.sigDevs || [];
    },
    sigDevLoading: function sigDevLoading() {
      return this.$store.state.trkd.fetchingSigDevs;
    },
    sigDevError: function sigDevError() {
      return this.$store.state.trkd.sigDevsError;
    },
    error: function error() {
      return !this.companyHeadlines && !this.companySigDevs && this.$store.state.trkd.sigDevsError;
    },
    loading: function loading() {
      return this.$store.state.trkd.fetchingCompanyHeadlines && !this.companyHeadlines;
    },
    ticker: function ticker() {
      return this.$store.state.ciq.ticker;
    },
    trkdTicker: function trkdTicker() {
      return this.$store.state.trkd.ticker;
    }
  },
  mounted: function mounted() {
    this.$emit("closechart");
    this.sendEvent(this.events.newsPageView, _objectSpread({
      event: "newsPageView"
    }, this.ticker));
  }
});